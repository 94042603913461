import React from 'react';

const GoogleFormEmbed = () => {
  return (
    <div className="google-form-container">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfxRalpqn8SxPHxNYdvAuwoZK40TSNr7UMuQtlCddRNwXyg4A/viewform?embedded=true"
        width="100%"
        height="1000"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Google Form"
        allowFullScreen
      >
        Loading…
      </iframe>
    </div>
  );
};

export default GoogleFormEmbed;
